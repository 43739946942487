import React from 'react'
import MainLayout from '../Navigation/MainLayout'
import AddResourceTemplate from '../Template/AddResource'
import { BASE_URL } from '../../Api/Constants'

function AddMonthlyCA() {
    return (
        <MainLayout>
            <AddResourceTemplate
                title="Add Monthly CA/Books"
                fields={[
                    { name: 'name', label: 'Name' },
                    { name: 'imageURL', label: 'Image URL' },
                    {
                        name: 'type',
                        label: 'Type',
                        type: 'select',
                        options: [
                            { value: 'monthly_ca', label: 'Monthly CA' },
                            { value: 'books', label: 'Books' },
                        ],
                    },
                    { name: 'downloadURL', label: 'Download URL' },
                ]}
                apiEndpoint={`${BASE_URL}/api/admin/v1/monthly-ca`}
                onSuccessNavigate={-1}
                buttonText="Add Monthly CA"
                successMessage="Monthly CA added successfully!"
            />
        </MainLayout>
    )
}

export default AddMonthlyCA