import React from 'react';
import MainLayout from '../Navigation/MainLayout';
import ResourceManagementTemplate from '../Template/ResourceManagement';
import { BASE_URL } from '../../Api/Constants';

export default function MonthlyCA() {
    return (
        <MainLayout>
            <ResourceManagementTemplate
                resourceName="Monthly CA/ Books"
                fields={[
                    { name: 'name', label: 'Name', type: 'text' },
                    { name: 'downloadURL', label: 'Download URL', type: 'text' },
                    { name: 'imageURL', label: 'Image URL', type: 'text' },
                    { name: 'type', label: 'Type', type: 'text' },
                ]}
                apiEndpoints={{
                    get: `${BASE_URL}/api/admin/v1/monthly-ca?`,
                    put: `${BASE_URL}/api/admin/v1/monthly-ca`,
                    delete: `${BASE_URL}/api/admin/v1/monthly-ca`
                }}
            />
        </MainLayout>
    );
}
