/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Modal,
    MenuItem,
    Select,
    FormControl,
    IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatDateToYYYYMMDD, showTimeInAgoFormat } from '../../Utils/Time';

// Utility function to capitalize text
const capitalizeText = (text) => text;

const ResourceManagementTemplate = ({
    resourceName = 'Resource',
    fields = [],
    apiEndpoints = {},
    categories = []
}) => {
    const navigate = useNavigate();
    const [resourceList, setResourceList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [hasMore, setHasMore] = useState(true); // Infinite scroll state
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedResource, setSelectedResource] = useState(null);
    const [editFormData, setEditFormData] = useState({});
    const [page, setPage] = useState(1);

    // Fetch initial data
    useEffect(() => {
        fetchResources(true);
        window.scrollTo(0, 0);
    }, [selectedCategory]);

    const fetchResources = async (reset = false) => {
        try {
            const query = `&page=${reset ? 1 : page}${selectedCategory ? `&category=${selectedCategory}` : ''}`;
            const response = await axios.get(`${apiEndpoints.get}${query}`);
            const resources = response.data.data.list || [];
            if (reset) {
                setResourceList(resources);
            } else {
                setResourceList((prev) => [...prev, ...resources]);
            }
            setHasMore(resources.length > 0); // Stop fetching if no more data
            if (reset) setPage(2);
            else setPage((prevPage) => prevPage + 1);
        } catch (error) {
            console.error(`Error fetching ${resourceName.toLowerCase()}s:`, error);
            toast.error(`Failed to fetch ${resourceName.toLowerCase()}s`);
        }
    };

    const handleSearch = async () => {
        if (searchQuery.trim() === '') {
            fetchResources(true);
            return;
        }
        try {
            const query = `&text=${searchQuery}${selectedCategory ? `&category=${selectedCategory}` : ''}`;
            const response = await axios.get(`${apiEndpoints.get}${query}`);
            setResourceList(response.data.data.list || []);
            setHasMore(false);
            toast.success('Search results updated', { position: 'bottom-left' });
        } catch (error) {
            console.error('Error fetching search results:', error);
            toast.error('Failed to fetch search results');
        }
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        fetchResources(true);
    };

    const handleEditClick = (resource) => {
        setSelectedResource(resource);
        const formData = {};
        fields.forEach((field) => {
            formData[field.name] = resource[field.name] || '';
        });
        setEditFormData(formData);
        setEditModalOpen(true);
    };

    const handleDeleteClick = (resource) => {
        setSelectedResource(resource);
        setDeleteModalOpen(true);
    };

    const handleEditSave = async () => {
        try {
            await axios.put(`${apiEndpoints.put}/${selectedResource._id}`, editFormData);
            toast.success(`${resourceName} updated successfully`);
            setEditModalOpen(false);
            fetchResources(true); // Refetch the list after update
        } catch (error) {
            console.error(`Error updating ${resourceName.toLowerCase()}:`, error);
            toast.error(`Failed to update ${resourceName.toLowerCase()}`);
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${apiEndpoints.delete}/${selectedResource._id}`);
            toast.success(`${resourceName} deleted successfully`);
            setDeleteModalOpen(false);
            fetchResources(true); // Refetch the list after delete
        } catch (error) {
            console.error(`Error deleting ${resourceName.toLowerCase()}:`, error);
            toast.error(`Failed to delete ${resourceName.toLowerCase()}`);
        }
    };

    const handleFieldChange = (field) => (event) => {
        setEditFormData({
            ...editFormData,
            [field]: event.target.value,
        });
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        {resourceName} Management
                    </Typography>
                    <Box display='flex' alignItems='center' mb={3}>
                        <TextField
                            size='small'
                            variant='outlined'
                            placeholder='Search...'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{ backgroundColor: 'white', width: '400px' }}
                        />
                        <FormControl size='small' sx={{ ml: 2, minWidth: 200 }}>
                            <Select
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                                displayEmpty
                            >
                                <MenuItem value=''>All</MenuItem>
                                {categories.map((category) => (
                                    <MenuItem key={category.value} value={category.value}>
                                        {category.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <IconButton onClick={handleSearch} sx={{ ml: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                    <InfiniteScroll
                        dataLength={resourceList.length}
                        next={() => fetchResources(false)}
                        hasMore={hasMore}
                        loader={<Typography>Loading...</Typography>}
                    >
                        <Box sx={{ overflowX: 'auto', width: '100%' }}>
                            <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                                <Table sx={{ minWidth: 650, tableLayout: 'fixed' }} aria-label="resource table">
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#262726' }}>
                                            <TableCell sx={{ color: 'white', fontWeight: 'bold', whiteSpace: 'nowrap' }}>S.No</TableCell>
                                            {fields.map((field) => (
                                                <TableCell
                                                    key={field.name}
                                                    sx={{
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        whiteSpace: 'nowrap', // Prevent header text from wrapping
                                                    }}
                                                >
                                                    {capitalizeText(field.label)}
                                                </TableCell>
                                            ))}
                                            <TableCell sx={{ color: 'white', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Date</TableCell>
                                            <TableCell sx={{ color: 'white', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Edit</TableCell>
                                            <TableCell sx={{ color: 'white', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Delete</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {resourceList.map((resource, index) => (
                                            <TableRow
                                                key={resource._id}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    '&:hover': { backgroundColor: '#f5f5f5' },
                                                }}
                                            >
                                                <TableCell
                                                    sx={{
                                                        whiteSpace: 'normal', // Enable wrapping
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    {index + 1}
                                                </TableCell>
                                                {fields.map((field) => (
                                                    <TableCell
                                                        key={`${resource._id}-${field.name}`}
                                                        sx={{
                                                            whiteSpace: 'normal', // Enable wrapping
                                                            wordWrap: 'break-word',
                                                        }}
                                                    >
                                                        {field.type === 'select'
                                                            ? capitalizeText(resource[field.name]?.label || resource[field.name])
                                                            : capitalizeText(resource[field.name])}
                                                    </TableCell>
                                                ))}
                                                <TableCell
                                                    sx={{
                                                        whiteSpace: 'normal', // Enable wrapping
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    {`${showTimeInAgoFormat(resource.createdAt)}, ${formatDateToYYYYMMDD(resource.createdAt)}`}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        whiteSpace: 'normal', // Enable wrapping
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleEditClick(resource)}
                                                    >
                                                        Edit
                                                    </Button>
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        whiteSpace: 'normal', // Enable wrapping
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => handleDeleteClick(resource)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </InfiniteScroll>
                </Box>
            </Box>

            {/* Modals remain unchanged */}
            {/* Edit Resource Modal */}
            <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
                <Box sx={{ ...modalStyle }}>
                    <Typography variant='h6' sx={{ mb: 2 }}>Edit {resourceName}</Typography>
                    {fields.map((field) =>
                        field.type === 'select' ? (
                            <FormControl fullWidth margin='normal' key={field.name}>
                                <Select value={editFormData[field.name] || ''} onChange={handleFieldChange(field.name)}>
                                    {field.options.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {capitalizeText(option.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <TextField
                                fullWidth
                                label={capitalizeText(field.label)}
                                variant='outlined'
                                multiline={true}
                                value={editFormData[field.name] || ''}
                                onChange={handleFieldChange(field.name)}
                                sx={{ mb: 2 }}
                                key={field.name}
                            />
                        )
                    )}
                    <Button variant='contained' color='primary' onClick={handleEditSave}>Save Changes</Button>
                </Box>
            </Modal>

            {/* Delete Resource Modal */}
            <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
                <Box sx={{ ...modalStyle }}>
                    <Typography variant='h6' sx={{ mb: 2 }}>Confirm Delete</Typography>
                    <Typography sx={{ mb: 2 }}>Are you sure you want to delete this {resourceName.toLowerCase()}?</Typography>
                    <Button variant='contained' color='error' onClick={handleDeleteConfirm}>Delete</Button>
                </Box>
            </Modal>

            <ToastContainer />
        </>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default ResourceManagementTemplate;
