import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Article, Home, LocalActivityOutlined, Logout, MilitaryTech, Person, QuestionAnswer, QuestionAnswerOutlined, QuestionMark, SubscriptionsSharp, Topic, TopicOutlined, TopicTwoTone } from '@mui/icons-material'
function DrawerNav() {
    const navigate = useNavigate()
    const handleRoute = (url) => {
        navigate('/' + url)
    }

    const isMobile = useMediaQuery('(max-width:600px)')

    const contestListItems = [
        {
            value: 'Home',
            route: '',
            icon: <Home />
        },
        {
            value: 'Add Topic',
            route: 'add-topic',
            icon: <Topic />
        },
        {
            value: 'Add Subtopic',
            route: 'add-subtopic',
            icon: <TopicOutlined />
        },
        {
            value: 'Add Question',
            route: 'add-question',
            icon: <QuestionMark />
        }
    ]

    const mainListItems = [
        {
            value: 'Topics',
            route: 'topics',
            icon: <Topic />
        },
        {
            value: 'Subtopics',
            route: 'subtopics',
            icon: <TopicOutlined />
        },
        {
            value: 'Questions',
            route: 'recent-activity',
            icon: <QuestionMark />
        }
    ]

    const mainListItems3 = [
        {
            value: 'Articles',
            route: 'articles',
            icon: <Article />
        },
        {
            value: 'Categories',
            route: 'categories',
            icon: <TopicOutlined />
        }
    ]

    const mainListItems2 = [
        {
            value: 'Suggestions',
            route: 'suggestions',
            icon: <Topic />
        },
        {
            value: 'Add Suggestion',
            route: 'add-suggestion',
            icon: <TopicOutlined />
        },
        {
            value: 'Monthly CA / Books',
            route: 'monthly-ca',
            icon: <Topic />
        },
        {
            value: 'Add Monthly CA / Books',
            route: 'add-monthly-ca',
            icon: <Topic />
        }
    ]

    const drawerWidth = 300

    return (
        <>
            <CssBaseline />
            <Drawer
                variant='permanent'
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    display: isMobile ? 'none' : 'block',
                    position: 'relative',
                    zIndex: 1
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {contestListItems.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {mainListItems.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {mainListItems3.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {mainListItems2.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        <ListItem key={12} disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    localStorage.clear()
                                    handleRoute('login')
                                }}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <ListItemText primary='Logout' />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    )
}

export default DrawerNav
