import React, { useState } from 'react';
import { Box, Button, TextField, Typography, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function AddResourceTemplate({
  title = 'Add New Resource',  // Title for the form
  fields = [],                 // Array of form fields (can include dropdowns)
  apiEndpoint = '',            // API endpoint for submitting the form
  onSuccessNavigate = -1,      // Where to navigate after success
  buttonText = 'Add Resource', // Button label
  successMessage = 'Resource added successfully!', // Success message
}) {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  // Handle form data change
  const handleChange = (field) => (event) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  };

  // Handle form submission
  const handleSaveChanges = async () => {
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        console.log(successMessage, data);
        setFormData({}); // Clear form
        navigate(onSuccessNavigate); // Navigate after success
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
      <Box p={2}>
        <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
          {title}
        </Typography>
        {fields.map((field) => (
          field.type === 'select' ? (
            <FormControl fullWidth margin="normal" key={field.name}>
              <InputLabel>{field.label}</InputLabel>
              <Select
                value={formData[field.name] || ''}
                onChange={handleChange(field.name)}
                label={field.label}
              >
                {field.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              key={field.name}
              label={field.label}
              value={formData[field.name] || ''}
              onChange={handleChange(field.name)}
              fullWidth
              variant='outlined'
              margin='normal'
            />
          )
        ))}

        <Box mt={2}>
          <Button variant='contained' color='success' onClick={handleSaveChanges}>
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Box >
  );
}
